// Packages
import React from 'react'
import { useController } from 'react-hook-form';
import PropTypes from 'prop-types';

// Components
import TextAreaField from '../TextAreaField';

const TextAreaFieldControlled = (props) => {
  const {
    control,
    disabled,
    helperText,
    id,
    label,
    maxLength,
    maxRows,
    minRows,
    name,
    onInputChange,
    placeholder,
    resize,
    tooltipTitle,
    required,
    inputValue = "",
  } = props;

  const {
    field: { onChange, value },
    fieldState: { error }
  } = useController({
    name,
    control,
    defaultValue: inputValue,
  });

  const auxOnChange = (data) => {
    let inputValue = data.target.value;
    if (inputValue.length > maxLength) {
      inputValue = inputValue.slice(0, maxLength);
    }
    data.target.value = inputValue;
    onChange(data);
    onInputChange && onInputChange(data.target.value);
  };

  return (
    <TextAreaField
      disabled={ disabled }
      error={ error }
      label={ label }
      helperText={ helperText }
      id={ id }
      inputLength={ value ? value.length : 0 }
      onChange={ auxOnChange }
      placeholder={ placeholder }
      maxLength={ maxLength }
      maxRows={ maxRows }
      minRows={ minRows }
      name={ name }
      resize={ resize ? resize : "auto"}
      tooltipTitle={ tooltipTitle }
      value={ value }
      required = {required}
    />
  )
}

TextAreaFieldControlled.propTypes = {
  control: PropTypes.object,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string, 
  maxLength: PropTypes.number,
  maxRows: PropTypes.number,
  minRows: PropTypes.number,
  name: PropTypes.string,
  onInputChange: PropTypes.func,
  placeholder: PropTypes.string,
  resize: PropTypes.string,
  tooltipTitle: PropTypes.string,
  required: PropTypes.bool,
}

export default TextAreaFieldControlled;