import axios from "axios";

const useToolsProvider = () => {
  // Get tools lists
  const listToolsProvider = (params) => {
    return axios({
      method: "get",
      url: `tools`,
      params: params,
    });
  };

  // Get Tool by id
  const getToolProvider = (id) => {
    return axios({
      method: "get",
      url: `tools/${id}`,
    });
  };

  // update question by id
  const updateToolProvider = (data, id) => {
    return axios({
      method: "put",
      url: `tools/${id}`,
      data,
    });
  };

  // Create tools
  const addToolsProvider = (data) => {
    return axios({
      method: "post",
      url: `tools`,
      data,
    });
  };

  // Get survey by id
  const getSurveyProvider = (id) => {
    return axios({
      method: "get",
      url: `surveys/${id}`,
    });
  };

  // Get survey section list
  const listSurveySectionsProvider = (page) => {
    return axios({
      method: "get",
      url: `survey-sections?page=${page}`,
    });
  };

  // Get Section by id
  const getSurveySectionProvider = (id) => {
    return axios({
      method: "get",
      url: `survey-sections/${id}`,
    });
  };

  // Create Section
  const addSectionProvider = (data) => {
    return axios({
      method: "post",
      url: `survey-sections`,
      data,
    });
  };

  // Update Section
  const updateSectionProvider = (data, id) => {
    return axios({
      method: "put",
      url: `survey-sections/${id}`,
      data,
    });
  };

  // Remove question from section
  const removeSectionProvider = (id) => {
    return axios({
      method: "delete",
      url: `survey-sections/${id}`,
    });
  };

  // Clone Section
  const cloneSectionProvider = (id) => {
    return axios({
      method: "post",
      url: `survey-sections/clone/${id}`,
    });
  };

  const updateToolSectionsProvider = (id, data) => {
    return axios({
      method: "put",
      url: `surveys/${id}`,
      data,
    });
  };

  // Clone Tool
  const cloneToolProvider = (id) => {
    return axios({
      method: "post",
      url: `tools/clone/${id}`,
    });
  };

  // Change order survey section questions
  const orderSurveySectionProvider = (data) => {
    return axios({
      method: "post",
      url: `survey-sections/order-questions`,
      data,
    });
  };

  // Add question to section
  const addQuestionProvider = (data) => {
    return axios({
      method: "post",
      url: "questions",
      data,
    });
  };

  // Remove question from section
  const removeQuestionProvider = (id) => {
    return axios({
      method: "delete",
      url: `questions/${id}`,
    });
  };

  // Get question by id
  const getQuestionProvider = (id) => {
    return axios({
      method: "get",
      url: `questions/${id}`,
    });
  };

  // update question by id
  const updateQuestionProvider = (data, id) => {
    return axios({
      method: "post",
      url: `questions/${id}/update`,
      data,
    });
  };

  // Update conditionals
  const updateConditionalsProvider = (data) => {
    return axios({
      method: "post",
      url: `conditions-array`,
      data,
    });
  };

  // Create passiveTracking List
  const addPassiveTrackingProvider = (data) => {
    return axios({
      method: "post",
      url: `passive-tracking-steps-array`,
      data,
    });
  };
  // Update passiveTracking List
  const updatePassiveTrackingProvider = (id, data) => {
    return axios({
      method: "put",
      url: `passive_trackings/${id}`,
      data,
    });
  };

  // Get PassiveTracking steps by id
  const getPassiveTrackingStepsProvider = (id) => {
    return axios({
      method: "get",
      url: `passive_trackings/${id}`,
    });
  };

  // Get passiveTracking Answers
  const getPassiveTrackingAnswersProvider = (params) => {
    return axios({
      method: "post", // Change this to get
      url: `passive_trackings_answers`,
      params: params,
    });
  };

  // Get passiveTracking Answers Pagination
  const getPassiveTrackingAnswersPagiProvider = (params) => {
    return axios({
      method: "get", // Change this to get
      url: `getPassiveTracking`,
      params: params,
    });
  };

  // Get Respot tools
  const getAnalyticToolsProvider = (data) => {
    return axios({
      method: "post", // Change this to get
      url: `tools/stadistics`,
      data,
    });
  };

  // Get questions by tool
  const getQuestionsToolProvider = (id) => {
    return axios({
      method: "get",
      url: `tools/questions/${id}`,
    });
  };

  const finishToolProvider = (data) => {
    return axios({
      method: "put",
      url: `tools/finish`,
      data: data,
    });
  };

  const cloneQuestionProvider = (id) => {
    return axios({
      method: "post",
      url: `questions/${id}/clone`,
    });
  };

  const deleteToolProvider = (id) => {
    return axios({
      method: "delete",
      url: `tools/${id}`,
    });
  };

  const getProfilingQuestionProvider = (params) => {
    return axios({
      method: "get",
      url: `profiling-questions`,
      params,
    });
  };

  const generateSurveyWithAI = (data) => {
    return axios({
      method: "post",
      url: `${process.env.REACT_APP_AI_GENERATION_URL}core/v1/survery/generate`,
      data,
    });
  };

  // Clone and order questions between sections
  const cloneQuestionBetweenSections = (id, data) => {
    return axios({
      method: "post",
      url: `questions/${id}/update`,
      data: { survey_section_id: data },
    });
  };

  const copilotAnalyticToolProvider = (data) => {
    return axios({
      method: "post",
      url: '/tools/stadistics/copilot',
      data,
    });
  }

  const getCopilotAnalyticToolStatusProvider = (data) => {
    return axios({
      method: "get",
      url: '/tools/stadistics/copilot',
      params: data,
    });
  }

  return {
    addToolsProvider,
    addSectionProvider,
    addQuestionProvider,
    removeQuestionProvider,
    listToolsProvider,
    getQuestionProvider,
    updateQuestionProvider,
    listSurveySectionsProvider,
    cloneSectionProvider,
    updateToolSectionsProvider,
    getSurveyProvider,
    getSurveySectionProvider,
    orderSurveySectionProvider,
    updateConditionalsProvider,
    getToolProvider,
    updateToolProvider,
    addPassiveTrackingProvider,
    getPassiveTrackingStepsProvider,
    updateSectionProvider,
    removeSectionProvider,
    getPassiveTrackingAnswersProvider,
    getAnalyticToolsProvider,
    getPassiveTrackingAnswersPagiProvider,
    cloneToolProvider,
    updatePassiveTrackingProvider,
    getQuestionsToolProvider,
    finishToolProvider,
    cloneQuestionProvider,
    deleteToolProvider,
    getProfilingQuestionProvider,
    generateSurveyWithAI,
    cloneQuestionBetweenSections,
    copilotAnalyticToolProvider,
    getCopilotAnalyticToolStatusProvider,
  };
};

export default useToolsProvider;
