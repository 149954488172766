import useHelpers from "../../../helpers";
import useStrings from "../../../strings";
import useInitialStates from "../../initialStates";

const useToolsReducers = () => {
  // Helpers
  const { useCreateReducer } = useHelpers();
  const { createReducer } = useCreateReducer();

  //strings
  const { useTypes } = useStrings();
  const { useToolsTypes } = useTypes();
  const {
    ADD_TOOLS,
    ORGANIZE_TOOLS_AI,
    UPDATE_TOOLS_AI,
    UPDATE_QUESTION_AI,
    UPDATE_OPTION_AI,
    REMOVE_QUESTION_AI,
    REMOVE_OPTION_AI,
    CLEAN_QUESTIONS_AI,
    SAVED_QUESTIONS,
    UPDATE_QUESTIONS,
    ADD_SECTION,
    CLEAN_QUESTIONS,
    CONDITIONALS_LIST,
    CLEAN_CONDITIONALS_LIST,
    ADD_OPTION_AI,
  } = useToolsTypes();

  // State
  const { useToolsInitialStates } = useInitialStates();
  const {
    addToolsInitialState,
    organizeToolsInitialStateAI,
    savedQuestionsInitialState,
    conditionalsListState,
    copilotAnalyticsInitialState,
  } = useToolsInitialStates();

  const addTools = createReducer(addToolsInitialState, {
    [ADD_TOOLS](state, action) {
      const { payload } = action;
      const { id, name, mision_id, tools } = payload;

      return {
        ...state,
        id: id,
        toolsId: tools?.id,
        name: name,
        mission: mision_id,
      };
    },
    [ADD_SECTION](state, action) {
      const { payload } = action;
      const { id } = payload;

      return {
        ...state,
        sectionId: id,
      };
    },
  });

  const organizeToolsAI = createReducer(organizeToolsInitialStateAI, {
    [ORGANIZE_TOOLS_AI](state, action) {
      const { payload } = action;

      return {
        ...state,
        id: payload?.id,
        name: payload?.name,
        questions: [...payload?.questions] || [],
      };
    },
    [UPDATE_TOOLS_AI](state, action) {
      const { payload } = action;

      return {
        ...state,
        id: payload?.id,
      };
    },
    [UPDATE_QUESTION_AI](state, action) {
      const { payload } = action;

      const modifiedQuestions = state.questions.map((question) => {
        if (question.title === payload.prevTitle) {
          return {
            ...question,
            title: payload.newTitle,
          };
        } else return question;
      });

      return {
        ...state,
        questions: modifiedQuestions,
      };
    },
    [UPDATE_OPTION_AI](state, action) {
      const { payload } = action;

      const questions = state.questions.map((question) => {
        if (question.title === payload.questionTitle) {
          const modifiedOptions = question.options.map((option) => {
            if (option === payload.prevOption) {
              return payload.newOption;
            } else return option;
          });
          return {
            ...question,
            options: modifiedOptions,
          };
        } else return question;
      });

      return {
        ...state,
        questions,
      };
    },
    [REMOVE_QUESTION_AI](state, action) {
      const { payload } = action;

      const modifiedQuestions = state.questions.filter((question) => {
        return question.title !== payload.title;
      });

      return {
        ...state,
        questions: modifiedQuestions,
      };
    },
    [REMOVE_OPTION_AI](state, action) {
      const { payload } = action;

      const questions = state.questions.map((question) => {
        if (question.title === payload.questionTitle) {
          const modifiedOptions = question.options.filter((option) => (
            option !== payload.option
          ));
          return {
            ...question,
            options: modifiedOptions,
          };
        } else return question;
      });

      return {
        ...state,
        questions,
      };
    },
    [CLEAN_QUESTIONS_AI]() {
      return {
        id: "",
        name: "",
        questions: [],
      };
    },
    [ADD_OPTION_AI](state, action) {
      const { payload } = action;
      const { questionTitle, newOption } = payload;

      const questions = state.questions.map((question) => {
        if (question.title === questionTitle) {
          const updatedOptions = [...question.options, newOption];

          return {
            ...question,
            options: updatedOptions,
          };
        } else {
          return question;
        }
      });

      return {
        ...state,
        questions,
      };
    },
  });

  const savedQuestions = createReducer(savedQuestionsInitialState, {
    [SAVED_QUESTIONS](state, action) {
      const { payload } = action;
      const { question_type, title, order, id } = payload;

      return [
        ...state,
        {
          title: title,
          questionType: question_type,
          order: order,
          id: id.toString(),
        },
      ];
    },
    [UPDATE_QUESTIONS](state, action) {
      const { payload } = action;

      return [...payload];
    },
    [CLEAN_QUESTIONS]() {
      return [];
    },
  });

  const conditionalsList = createReducer(conditionalsListState, {
    [CONDITIONALS_LIST](state, action) {
      const { payload } = action;
      const { question_id, condition, question_redirect_id, end_survey, index } = payload;
      if (state.length) {
        const conditionalList = state.filter((conditional) => {
          if (conditional?.question_id === question_id && conditional?.condition === condition) {
            return false;
          } else {
            return true;
          }
        });

        return [
          ...conditionalList,
          {
            ...(index >= 0 && { index }),
            question_id,
            condition,
            question_redirect_id,
            end_survey,
          },
        ];
      } else {
        return [
          ...state,
          {
            ...(index >= 0 && { index }),
            question_id,
            condition,
            question_redirect_id,
            end_survey,
          },
        ];
      }
    },
    [CLEAN_CONDITIONALS_LIST]() {
      return [];
    },
  });


  return {
    addTools,
    organizeToolsAI,
    savedQuestions,
    conditionalsList,
  };
};

export default useToolsReducers;

