import React from "react";
import PropTypes from "prop-types";

import { StyledButton, StyleLoading } from "./DefaultButton.styles";
import { CircularProgress } from "@mui/material";

/**
 * Button component
 */
const Button = ({
  color = "primary",
  children,
  fullWidth = false,
  className,
  onClick = () => {},
  disabled = false,
  variant = "contained",
  loading = false,
  name,
  rounded = false,
  startIcon,
  size = "small",
  type = "button",
  id,
}) => {
  if (loading) {
    return (
      <StyleLoading style={{ color: "red !important", display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </StyleLoading>
    );
  }

  return (
    <StyledButton
      type={type}
      variant={variant}
      disabled={disabled}
      className={className}
      onClick={onClick}
      fullWidth={fullWidth}
      color={color}
      name={name}
      rounded={String(rounded)}
      startIcon={startIcon}
      size={size}
      id={id}
    >
      {children}
    </StyledButton>
  );
};

Button.propTypes = {
  variant: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.oneOf(["iconArrowBack"]),
  iconSize: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "third",
    "success",
    "info",
    "error",
    "warning",
    "grey",
  ]),
  fullWidth: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.oneOf(["small", "large"]),
  type: PropTypes.oneOf(["button", "submit"]),
};

export default Button;
