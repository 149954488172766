import { Document, Packer, Paragraph, ImageRun, AlignmentType, BorderStyle, UnderlineType } from "docx";
import { saveAs } from 'file-saver';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'jspdf-autotable';


const useGeneral = () => {
  const convertDateForIos = (date) => {
    const arr = date.split(/[- : T .]/);
    date = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    return date;
  };
  // convert date in whatever format that you want
  const formatDate = (date, format = "Y-mm-dd", convert = true) => {
    if (convert) date = new Date(convertDateForIos(date));

    const map = {
      dd: date.getDate().toString().padStart(2, "0"),
      mm: date.getMonth() + 1 > 9 ? date.getMonth() + 1 : "0" + (date.getMonth() + 1),
      Y: date.getFullYear(),
      yy: date.getFullYear().toString().slice(-2),
      HH: date.getHours() > 9 ? date.getHours() : "0" + date.getHours(),
      MM: date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes(),
      SS: date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds(),
    };

    return format.replace(/dd|mm|yy|Y|HH|MM|SS/gi, (matched) => map[matched]);
  };

  // convert date to this string format (yyyy-mm-dd hh:mm:ss) to send to the back
  const dateFormatUTC = (FieldsDate) => {
    const date = formatDate(new Date(FieldsDate[0]), "Y-mm-dd", false);
    const time = new Date(FieldsDate[1]);
    const hour =
      FieldsDate[2] === "pm" && time.getHours() < 12
        ? time.getHours() + 12
        : time.getHours() > 12 && FieldsDate[2] === "am"
          ? time.getHours() - 12
          : time.getHours();

    return `${date} ${hour.toString().padStart(2, "0")}:${time
      .getMinutes()
      .toString()
      .padStart(2, "0")}:00`;
  };

  const getAmPmFormat = (date) => {
    const time = new Date(date);
    const hour = time.getHours();

    return hour >= 12 ? "pm" : "am";
  };

  const openApp = () => {
    window.location = "membeer://";
  };

  const generatePDF = async (htmlContent, selectTool) => {
    const doc = new jsPDF();
    let startY = 10;

    // Función para agregar texto con ajuste de líneas y control de salto de página
    const addText = (text, x, y, maxWidth, isList, isHeader) => {
      const lines = doc.splitTextToSize(text, maxWidth);
      for (const line of lines) {
        if (y > doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 10;
        }
        doc.text(line, x, y);
        if (isHeader) {
          y += 8;
        }
        else if (isList) {
          y += 3;
        }
        else {
          y += 5;
        }
      }
      return y;
    };

    // Función para dibujar una línea horizontal
    const drawHorizontalLine = () => {
      const pageWidth = doc.internal.pageSize.width;
      doc.setLineWidth(0.5);
      doc.line(10, startY, pageWidth - 10, startY);
      startY += 5;
    };

    // Función para procesar encabezados
    const processHeader = (element, fontSize, fontStyle) => {
      doc.setTextColor(0, 0, 0)
      const cleanText = element.innerText.trim().replace(/\s+/g, ' ');
      doc.setFont('helvetica', fontStyle);
      doc.setFontSize(fontSize);
      startY = addText(cleanText, 10, startY, 180, false, true);
    };

    // Función para procesar párrafos y listas
    const processParagraphOrList = (element, isList, afterGraphic = false, intoHeader = false) => {
      const cleanText = element.innerText.trim().replace(/\s+/g, ' ');
      const text = isList ? `    • ${cleanText}` : cleanText;

      // Si después de un gráfico, poner el texto en itálico y gris
      const fontStyle = afterGraphic ? 'italic' : 'normal';
      afterGraphic || intoHeader ? doc.setTextColor(169, 169, 169) : doc.setTextColor(0, 0, 0); // Cambiar el color del texto

      doc.setFont('helvetica', fontStyle);
      doc.setFontSize(12);
      startY = addText(text, 10, startY, 180, isList);
      if (!intoHeader) {
        startY += 5; // Espacio adicional después de cada párrafo o lista
      }
    };

    // Función para procesar gráficos
    const processGraphic = async (element) => {
      try {
        const imageBase64 = await domtoimage.toPng(element);
        const img = new Image();
        img.src = imageBase64;

        await new Promise((resolve) => (img.onload = resolve));
        const originalWidth = img.width;
        const originalHeight = img.height;
        const maxWidth = 180;
        const maxHeight = 90;

        const widthRatio = maxWidth / originalWidth;
        const heightRatio = maxHeight / originalHeight;
        const scaleRatio = Math.min(widthRatio, heightRatio);

        const adjustedWidth = originalWidth * scaleRatio;
        const adjustedHeight = originalHeight * scaleRatio;

        const pageWidth = doc.internal.pageSize.width;
        const xPosition = (pageWidth - adjustedWidth) / 2;

        if (startY + adjustedHeight > doc.internal.pageSize.height - 10) {
          doc.addPage();
          startY = 10;
        }

        doc.addImage(imageBase64, 'PNG', xPosition, startY, adjustedWidth, adjustedHeight);
        startY += adjustedHeight + 10;
      } catch (error) {
        throw error;
      }
    };

    const processTable = (element) => {
      const table = element;

      // Luego, procesamos la tabla directamente con autoTable
      doc.autoTable({
        html: table,
        startY: startY,
        theme: 'grid',
        headStyles: { 
          fillColor: [255, 255, 255], // Fondo blanco
          textColor: [0, 0, 0],       // Texto negro
          lineColor: [200, 200, 200], // Bordes grises claros
          lineWidth: 0.1,             // Ancho de los bordes
          halign: 'center',            // Alinear texto horizontalmente al centro
          valign: 'middle'
      },
        columnStyles: {
          0: { halign: 'center', valign: 'middle' },
          1: { halign: 'center', valign: 'middle' },
          2: { halign: 'center', valign: 'middle' },
          3: { halign: 'center', valign: 'middle' },
        }
      });

      // Actualizamos la posición después de la tabla
      startY = doc.lastAutoTable.finalY + 10; // Aseguramos que la posición esté después de la tabla
    };



    // Función para procesar secciones
    const processSection = async (section) => {
      const isHeader = section.tagName.toLowerCase() === 'header';
      const elements = section.querySelectorAll('*');

      let afterGraphic = false; // Variable para saber si un p viene después de un gráfico

      for (const element of elements) {
        const tag = element.tagName.toLowerCase();
        switch (tag) {
          case 'h1':
            processHeader(element, 24, 'bold');
            break;
          case 'h2':
            startY += 5;
            processHeader(element, 20, 'bold');
            break;
          case 'h3':
            processHeader(element, 16, 'normal');
            break;
          case 'p':
            // Si el párrafo está dentro de un header, hacerlo gris
            if (section.tagName.toLowerCase() === 'header') {
              processParagraphOrList(element, false, afterGraphic, true);
            } else {
              processParagraphOrList(element, false, afterGraphic, false);
            }
            afterGraphic = false; // Desactivar el estado de "después de gráfico"
            break;
          case 'li':
            processParagraphOrList(element, true, false, false);
            break;
          case 'div':
            if (element.id.includes('grafico')) {
              await processGraphic(element);
              afterGraphic = true; // Activar el estado de "después de gráfico"
            }
            break;
          case 'table':
            processTable(element);
            break;
          default:
            break;
        }
      }

      if (isHeader) drawHorizontalLine();
    };

    // Procesar todas las secciones y encabezados
    const iframe = htmlContent.querySelector('iframe');
    const iframeDoc = iframe.contentDocument;
    const sections = iframeDoc.querySelectorAll('section, header');
    for (const section of sections) {
      await processSection(section);
    }

    // Guardar el archivo PDF
    const fileName = selectTool?.name ? `${selectTool.name}-copilot-analytics.pdf` : 'documento.pdf';
    doc.save(fileName);
  };

  const generateWord = async (htmlContent, selectTool) => {
    const children = [];

    // Función para procesar encabezados (h1, h2, h3)
    const processHeader = (element, headingLevel) => {
      const cleanText = element.innerText.trim().replace(/\s+/g, ' ');
      children.push(new Paragraph({ text: cleanText, style: headingLevel }));
    };

    // Función para procesar párrafos y listas
    const processParagraphOrList = (element, isList, afterGraphic) => {
      const cleanText = element.innerText.trim().replace(/\s+/g, ' ');
      const text = isList ? `   • ${cleanText}` : cleanText;
      children.push(new Paragraph({ text, style: afterGraphic ? 'aside' : 'wellSpaced' }));
    };

    // Función para procesar gráficos
    const processGraphic = async (element) => {
      try {
        const imageBlob = await domtoimage.toBlob(element, {
          style: true, // Asegura que los estilos sean aplicados
          externalCss: true, // Asegura que los CSS externos sean cargados (si es necesario)
        });
        if (imageBlob) {
          const base64data = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]); // Extraer solo el base64
            reader.onerror = () => reject('Error al convertir la imagen a base64');
            reader.readAsDataURL(imageBlob);
          });

          const newWidth = 400;
          const aspectRatio = element.offsetWidth / element.offsetHeight;
          const newHeight = newWidth / aspectRatio;

          const pageWidth = 595;  // Tamaño estándar de página en puntos (A4)
          const pageHeight = 842; // Tamaño estándar de página en puntos (A4)

          // Limitar el tamaño de la imagen si excede el tamaño de la página
          const maxWidth = pageWidth - 72;  // Margen de 1 pulgada (72 puntos)
          const maxHeight = pageHeight - 72;

          const adjustedWidth = Math.min(newWidth, maxWidth);
          const adjustedHeight = Math.min(newHeight, maxHeight);

          children.push(
            new Paragraph({ text: '', style: 'wellSpaced' }),
            new Paragraph({
              children: [
                new ImageRun({
                  data: base64data,
                  transformation: { width: adjustedWidth, height: adjustedHeight }
                })
              ],
              alignment: AlignmentType.CENTER
            }),
            new Paragraph({ text: '', style: 'wellSpaced' })
          );
        }
      } catch (error) {
        console.error('Error al procesar gráfico:', error);
        throw error;
      }
    };

    const processTable = async (element) => {
      try {
        // Paso 1: Capturar la tabla como una imagen usando html2canvas
        const canvas = await html2canvas(element, {
          useCORS: true, // Habilitar CORS si es necesario
          logging: false, // Desactivar logs para mayor rendimiento
        });

        // Paso 2: Convertir el canvas a base64
        const base64Image = canvas.toDataURL('image/png');

        // Paso 3: Calcular las dimensiones ajustadas
        const originalWidth = canvas.width;
        const originalHeight = canvas.height;
        const maxWidth = 595;  // Tamaño estándar de página en puntos (A4)
        const maxHeight = 842; // Tamaño estándar de página en puntos (A4)

        // Calcular la proporción para ajustar el tamaño de la imagen
        const aspectRatio = originalWidth / originalHeight;
        let adjustedWidth = maxWidth - 72; // Margen de 1 pulgada (72 puntos)
        let adjustedHeight = adjustedWidth / aspectRatio;

        // Asegurarse de que la altura no exceda el tamaño máximo
        if (adjustedHeight > maxHeight - 72) {
          adjustedHeight = maxHeight - 72;
          adjustedWidth = adjustedHeight * aspectRatio;
        }

        // Paso 4: Crear el párrafo que contendrá la imagen
        children.push(
          new Paragraph({ text: '', style: 'wellSpaced' }),
          new Paragraph({
            children: [
              new ImageRun({
                data: base64Image.split(',')[1], // Solo la parte base64 de la imagen
                transformation: { width: adjustedWidth, height: adjustedHeight },
              }),
            ],
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({ text: '', style: 'wellSpaced' })
        );
      } catch (error) {
        console.log('Error al procesar la tabla:', error);
        throw error;
      }
    };

    // Función para procesar secciones
    const processSection = async (section) => {
      const isHeader = section.tagName.toLowerCase() === 'header';
      let afterGraphic = false;
      const elements = section.querySelectorAll('*');

      for (const element of elements) {
        const tag = element.tagName.toLowerCase();
        switch (tag) {
          case 'h1':
            processHeader(element, 'Heading1');
            break;
          case 'h2':
            processHeader(element, 'Heading2');
            break;
          case 'h3':
            processHeader(element, 'Heading3');
            break;
          case 'p':
            processParagraphOrList(element, false, afterGraphic);
            afterGraphic = false;
            break;
          case 'li':
            processParagraphOrList(element, true);
            break;
          case 'div':
            if (element.id.includes('grafico')) {
              await processGraphic(element);
              afterGraphic = true;
            }
            if (element.id.includes('demographic-table')) {
              await processTable(element);
            }
            break;
          default:
            break;
        }
      }

      if (isHeader) {
        children.push(new Paragraph({
          text: '',
          border: {
            bottom: { style: BorderStyle.SINGLE, size: 2, color: '000000' }
          }
        }));
      }
    };

    // Procesar todas las secciones y encabezados
    const iframe = htmlContent.querySelector('iframe');
    const iframeDoc = iframe.contentDocument;
    const sections = iframeDoc.querySelectorAll('section, header');
    for (const section of sections) {
      await processSection(section);
    }

    // Generar el documento Word
    const docStyles = {
      styles: {
        paragraphStyles: [
          {
            id: "Heading1",
            name: "Heading 1",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 28,
              bold: true,
            },
            paragraph: {
              spacing: {
                after: 120,
              },
            },
          },
          {
            id: "Heading2",
            name: "Heading 2",
            basedOn: "Normal",
            next: "Normal",
            quickFormat: true,
            run: {
              size: 26,
              bold: true,
              underline: {
                type: UnderlineType.SINGLE,
              },
            },
            paragraph: {
              spacing: {
                before: 240,
                after: 120,
              },
            },
          },
          {
            id: "aside",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              color: "999999",
              italics: true,
            },
            paragraph: {
              indent: {
                left: 720,
              },
              spacing: {
                line: 276,
                before: 72,
                after: 240,
              },
            },
          },
          {
            id: "wellSpaced",
            name: "Well Spaced",
            basedOn: "Normal",
            quickFormat: true,
            paragraph: {
              spacing: { line: 276, before: 72, after: 36 },
            },
          },
        ],
      },
    };
    const doc = new Document({
      ...docStyles,
      sections: [{ children }]
    });

    const fileName = selectTool?.name ? `${selectTool.name}-copilot-analytics.docx` : 'documento.docx';

    try {
      const blob = await Packer.toBlob(doc);
      saveAs(blob, fileName);
    } catch (error) {
      throw error;
    }
  };


  return { dateFormatUTC, formatDate, getAmPmFormat, openApp, generatePDF, generateWord };
};

export default useGeneral;

