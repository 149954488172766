// Packages
import styled from 'styled-components';
import tw from 'twin.macro';

// Components
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)`
  && {
    ${tw`mb-7`}
  }

  .MuiInputBase-input {
    ${tw`
      text-neutral
      font-light
      text-sm
      box-border
      mt-2
      bg-neutral-30
      border border-neutral-60 border-solid
      rounded
      px-4
      h-auto
    `}
    padding-top: 9.5px;
    padding-bottom: 9.5px;
    letter-spacing: -0.006em;

    &::placeholder {
      ${tw`opacity-100 text-neutral-70`}
    }
    &:-ms-input-placeholder {
      ${tw`opacity-100 text-neutral-70`}
    }
    &::-webkit-input-placeholder {
      ${tw`opacity-100 text-neutral-70`}
    }
    &:disabled {
      ${tw`
      text-neutral
      font-light
      text-sm
      bg-neutral-30
    `}
    -webkit-text-fill-color: #363635;
    }
  }

  .MuiInput-root {
    ${tw`mt-0`}
    &:before {
      content: none;
    }
    &:after {
      content: none;
    }
  }

  .Mui-error {
    .MuiInputBase-input {
      ${tw`border-danger`}
    }
  }

  .MuiFormHelperText-root {
    ${tw`left-0 text-xs font-light text-neutral-80`}
    ${(props) => props.error && tw`text-danger`}
    bottom: -22px;
  }

  .MuiInputLabel-root {
    ${tw`transform-none relative`}
    display: flex !important;
  }
`;
