import useApi from "../../index";
import useTypes from "../../../strings/types";

// Constants
import {
  AI_SECTION_TITLE,
  AI_SECTION_DESCRIPTION,
  ERRORS,
  QUESTION_TYPES,
  QUESTION_TYPES_WITH_OPTIONS,
} from "../../../config/constants/toolsAI";

const useToolsActions = () => {
  // Services
  const { useServices } = useApi();
  const { useToolsServices } = useServices();
  const {
    addToolsService,
    addSectionService,
    addQuestionService,
    removeQuestionService,
    getQuestionService,
    ListToolsService,
    updateQuestionService,
    listSurveySectionsService,
    cloneSectionService,
    updateToolSectionsService,
    getSurveyService,
    getSurveySectionService,
    orderSurveySectionService,
    updateConditionalsService,
    getToolService,
    updateToolService,
    addPassiveTrackingService,
    getPassiveTrackingStepsService,
    updateSectionService,
    removeSectionService,
    getPassiveTrackingAnswersService,
    getAnalyticToolsService,
    getPassiveTrackingAnswersPagiService,
    cloneToolService,
    updatePassiveTrackingService,
    getQuestionsToolService,
    finishToolService,
    cloneQuestionService,
    deleteToolService,
    getProfilingQuestionService,
    generateSurveyWithAIService,
    cloneQuestionBetweenSectionsService,
    copilotAnalyticToolService,
    getCopilotAnalyticToolStatusService
  } = useToolsServices();

  // Strings
  const { useToolsTypes } = useTypes();
  const {
    ADD_TOOLS,
    ORGANIZE_TOOLS_AI,
    UPDATE_TOOLS_AI,
    UPDATE_QUESTION_AI,
    UPDATE_OPTION_AI,
    REMOVE_QUESTION_AI,
    REMOVE_OPTION_AI,
    CLEAN_QUESTIONS_AI,
    SAVED_QUESTIONS,
    UPDATE_QUESTIONS,
    ADD_SECTION,
    CLEAN_QUESTIONS,
    CONDITIONALS_LIST,
    CLEAN_CONDITIONALS_LIST,
    ADD_OPTION_AI,
  } = useToolsTypes();

  const actListTools = (params, onSuccess, onError) => async () => {
    try {
      const response = await ListToolsService(params);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actGetTool = (id, onSuccess, onError) => async () => {
    try {
      const response = await getToolService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actUpdateTool =
    ({ data, id }, onSuccess, onError) =>
    async () => {
      try {
        const response = await updateToolService(data, id);
        onSuccess && onSuccess(response.data.data);
      } catch (e) {
        onError && onError(e);
      }
    };

  const actAddTools = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await addToolsService(data);

      dispatch({
        type: ADD_TOOLS,
        payload: response.data.data,
      });

      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actGetAnalyticTools = (data, onSuccess, onError) => async () => {
    try {
      const response = await getAnalyticToolsService(data);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actGetSurvey = (id, onSuccess, onError) => async () => {
    try {
      const response = await getSurveyService(id);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      console.error(error);
      onError && onError();
    }
  };

  const actGetSurveySection = (id, onSuccess, onError) => async () => {
    try {
      const response = await getSurveySectionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actListSurveySections =
    ({ page }, onSuccess, onError) =>
    async () => {
      try {
        const response = await listSurveySectionsService(page);
        onSuccess && onSuccess(response.data);
      } catch (error) {
        onError && onError();
      }
    };

  const actAddSection = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await addSectionService(data);

      dispatch({
        type: ADD_SECTION,
        payload: response.data.data,
      });

      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actCloneSection = (id, onSuccess, onError) => async () => {
    try {
      const response = await cloneSectionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actUpdateToolSections = (data, onSuccess, onError) => async () => {
    const {id, sections} = data;
    try {
      const response = await updateToolSectionsService(id, sections);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actCloneTool = (id, onSuccess, onError) => async () => {
    try {
      const response = await cloneToolService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actUpdateSection =
    ({ data, id }, onSuccess, onError) =>
    async () => {
      try {
        const response = await updateSectionService(data, id);
        onSuccess && onSuccess(response.data.data);
      } catch {
        onError && onError();
      }
    };

  const actRemoveSection = (id, onSuccess, onError) => async () => {
    try {
      const response = await removeSectionService(id);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actOrderSurveySection = (data, onSuccess, onError) => async () => {
    try {
      const response = await orderSurveySectionService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actAddQuestion = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await addQuestionService(data);

      dispatch({
        type: SAVED_QUESTIONS,
        payload: response.data.data,
      });

      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError();
    }
  };

  const actUpdateQuestions = (data) => (dispatch) => {
    dispatch({
      type: UPDATE_QUESTIONS,
      payload: data,
    });
  };

  const actCleanQuestions = () => (dispatch) => {
    dispatch({
      type: CLEAN_QUESTIONS,
    });
  };

  const actRemoveQuestion = (id, onSuccess, onError) => async () => {
    try {
      const response = await removeQuestionService(id);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actGetQuestion = (id, onSuccess, onError) => async () => {
    try {
      const response = await getQuestionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actUpdateQuestion =
    ({ data, id }, onSuccess, onError) =>
    async () => {
      try {
        const response = await updateQuestionService(data, id);
        onSuccess && onSuccess(response.data.data);
      } catch {
        onError && onError();
      }
    };

  const actAddConditionalList = (data) => (dispatch) => {
    dispatch({
      type: CONDITIONALS_LIST,
      payload: data,
    });
  };

  const actCleanConditionalList = () => (dispatch) => {
    dispatch({
      type: CLEAN_CONDITIONALS_LIST,
    });
  };

  const actUpdateConditionals = (data, onSuccess, onError) => async () => {
    try {
      const response = await updateConditionalsService(data);
      if (response.status === 400) {
        throw response.data;
      }
      onSuccess && onSuccess(response.data.data);
    } catch (e) {
      onError && onError(e);
    }
  };

  const actAddPassiveTracking = (data, onSuccess, onError) => async () => {
    try {
      const response = await addPassiveTrackingService(data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actUpdatePassiveTracking = (id, data, onSuccess, onError) => async () => {
    try {
      const response = await updatePassiveTrackingService(id, data);
      onSuccess && onSuccess(response.data.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actGetPassiveTrackingSteps = (id, onSuccess, onError) => async () => {
    try {
      const response = await getPassiveTrackingStepsService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actGetPassiveTrackingAnswers = (params, onSuccess, onError) => async () => {
    try {
      const response = await getPassiveTrackingAnswersService(params);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actGetPassiveTrackingPagiAnswers = (params, onSuccess, onError) => async () => {
    try {
      const response = await getPassiveTrackingAnswersPagiService(params);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actGetQuestionsTool = (id, onSuccess, onError) => async () => {
    try {
      const response = await getQuestionsToolService(id);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };
  const actFinishTool = (data, onSuccess, onError) => async () => {
    try {
      const response = await finishToolService(data);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  const actCloneQuestion = (id, onSuccess, onError) => async () => {
    try {
      const response = await cloneQuestionService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actDeleteTool = (id, onSuccess, onError) => async () => {
    try {
      const response = await deleteToolService(id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actGetProfilingQuestion = (params, onSuccess, onError) => async () => {
    try {
      const response = await getProfilingQuestionService(params);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actGenerateSurveyAI = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const response = await generateSurveyWithAIService(data);

      if (onSuccess && response?.data?.data) {
        if(response.data.status == "success"){
          const questionsAI = response.data.data.questions.map((question) => {

            const questionData = {
              title: question.title,
              question_type: question.question_type,
              question_type_spanish: QUESTION_TYPES[question.question_type].es,
            };
  
            if (QUESTION_TYPES_WITH_OPTIONS.includes(question.question_type)) {
              question.options?.length > 0
                ? questionData.options = question.options
                : questionData.options = [];
            };
            return questionData;
          });
  
          const payloadAI = {
            id: 1,
            name: response.data.data.survey_title,
            questions: questionsAI,
          };

          dispatch({
            type: ORGANIZE_TOOLS_AI,
            payload: payloadAI,
          });
          onSuccess(payloadAI);
        } else {
          onError(ERRORS[response.data?.code] || ERRORS.GENERAL_ERROR);
        }
        
      } else if (onError) {
        onError(ERRORS[response.data?.code] || ERRORS.GENERAL_ERROR);
      }
    } catch (error) {
      onError(ERRORS[response.data?.code] || ERRORS.GENERAL_ERROR);
    }
  };

  const actUpdateQuestionAI = (data) => (dispatch) => {
    dispatch({
      type: UPDATE_QUESTION_AI,
      payload: data,
    });
  };

  const actUpdateOptionAI = (data) => (dispatch) => {
    dispatch({
      type: UPDATE_OPTION_AI,
      payload: data,
    });
  };

  const actRemoveQuestionAI = (data) => (dispatch) => {
    dispatch({
      type: REMOVE_QUESTION_AI,
      payload: data,
    });
  };

  const actRemoveOptionAI = (data) => (dispatch) => {
    dispatch({
      type: REMOVE_OPTION_AI,
      payload: data,
    });
  };

  const actAddOptionAI = (data) => async (dispatch) => {
    dispatch({
      type: ADD_OPTION_AI,
      payload: data,
    });
  }

  const actAddSurveyAI = (data, onSuccess, onError) => async (dispatch) => {
    try {
      const dataForTool = {
        id: data.id,
        name: data.name,
        description: data.description,
        languaje: data.languaje,
        publish: data.publish,
        type_tool: data.type_tool,
        validation: data.validation,
        released: data.released,
      };
      const responseTool = await updateToolService(dataForTool, dataForTool.id);

      if (responseTool?.data?.data?.tools?.id) {
        const payload = {
          id: responseTool.data.data.tools.id
        };
  
        dispatch({
          type: UPDATE_TOOLS_AI,
          payload: payload,
        });
      };

      const dataForSection = {
        title: AI_SECTION_TITLE,
        description: AI_SECTION_DESCRIPTION,
        survey_id: responseTool.data.data.tools.id,
      };
      const sectionsTool = await addSectionService(dataForSection);
      onSuccess && onSuccess(sectionsTool.data.data);
    } catch {
      onError && onError(ERRORS.GENERAL_ERROR);
    }
  };

  const actCleanQuestionsAI = () => (dispatch) => {
    dispatch({
      type: CLEAN_QUESTIONS_AI,
    });
  };

  const actCloneQuestionsBetweenSections = (data, onSuccess, onError) => async () => {
    const {id, survey_section_id} = data;
    try {
      const response = await cloneQuestionBetweenSectionsService(id, survey_section_id);
      onSuccess && onSuccess(response.data.data);
    } catch {
      onError && onError();
    }
  };

  const actCopilotAnalyticTool = (data, onSuccess, onError) => async () => {
    try {
      const response = await copilotAnalyticToolService(data);
      onSuccess && onSuccess(response.data);
    } catch (error) {
      onError && onError(error);
    }
  };

  const actGetCopilotAnalyticToolStatus = (data, onSuccess, onError) => async () => {
    try {
      const response = await getCopilotAnalyticToolStatusService(data);
      onSuccess && onSuccess(response.data);
    } catch {
      onError && onError();
    }
  };

  return {
    actAddTools,
    actAddSection,
    actAddQuestion,
    actUpdateQuestions,
    actRemoveQuestion,
    actListTools,
    actGetQuestion,
    actUpdateQuestion,
    actListSurveySections,
    actCloneSection,
    actUpdateToolSections,
    actCleanQuestions,
    actGetSurvey,
    actGetSurveySection,
    actOrderSurveySection,
    actAddConditionalList,
    actCleanConditionalList,
    actUpdateConditionals,
    actGetTool,
    actUpdateTool,
    actAddPassiveTracking,
    actGetPassiveTrackingSteps,
    actUpdateSection,
    actRemoveSection,
    actGetPassiveTrackingAnswers,
    actGetAnalyticTools,
    actGetPassiveTrackingPagiAnswers,
    actCloneTool,
    actUpdatePassiveTracking,
    actGetQuestionsTool,
    actFinishTool,
    actCloneQuestion,
    actDeleteTool,
    actGetProfilingQuestion,
    actGenerateSurveyAI,
    actUpdateQuestionAI,
    actUpdateOptionAI,
    actRemoveQuestionAI,
    actRemoveOptionAI,
    actAddSurveyAI,
    actCleanQuestionsAI,
    actAddOptionAI,
    actCloneQuestionsBetweenSections,
    actCopilotAnalyticTool,
    actGetCopilotAnalyticToolStatus,
  };
};

export default useToolsActions;
