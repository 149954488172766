// Packages
import React from "react";
import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

import RadioButton from "../RadioButton";

import { StyledHelperText } from "../SelectField/SelectField.styles";

const RadioButtonControlled = (props) => {
  const { radioOptions, defaultValue, label, name, control, id } = props;

  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    defaultValue: defaultValue ?? "",
  });

  return (
    <FormControl>
      <FormLabel id={id}> {label} </FormLabel>
      <RadioGroup
        name={name}
        defaultValue={defaultValue}
        value={value || ""}
        onChange={onChange}
        error={error}
      >
        {radioOptions?.map((radio) => (
          <div className="flex" key={radio.value}>
            <RadioButton
              key={radio.value}
              value={radio.value}
              error={error}
              label={radio.label}
              variantColor={radio.variantColor}
              disabled={radio.disabled}
            />
            {radio.file && (
              <div className="mt-2">
                <img src={radio?.file} height="150px" width="150px" />
              </div>
            )}
          </div>
        ))}
      </RadioGroup>
      {error && <StyledHelperText>{error.message}</StyledHelperText>}
    </FormControl>
  );
};

RadioButtonControlled.prototype = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  control: PropTypes.object.isRequired,
  radioOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      variantColor: PropTypes.oneOf(["primary", "error", "success"]),
      disabled: PropTypes.bool,
    })
  ).isRequired,
};

export default RadioButtonControlled;
