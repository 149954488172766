const useToolsTypes = () =>{
  const ADD_TOOLS = "ADD_TOOLS";
  const SAVED_QUESTIONS = "SAVED_QUESTIONS";
  const UPDATE_QUESTIONS = "UPDATE_QUESTIONS";
  const ADD_SECTION = "ADD_SECTION";
  const CLEAN_QUESTIONS = "CLEAN_QUESTIONS";
  const CONDITIONALS_LIST = "CONDITIONALS_LIST";
  const CLEAN_CONDITIONALS_LIST = "CLEAN_CONDITIONALS_LIST";
  const ORGANIZE_TOOLS_AI = "ORGANIZE_TOOLS_AI";
  const UPDATE_TOOLS_AI = "UPDATE_TOOLS_AI";
  const UPDATE_QUESTION_AI = "UPDATE_QUESTION_AI";
  const UPDATE_OPTION_AI = "UPDATE_OPTION_AI";
  const REMOVE_QUESTION_AI = "REMOVE_QUESTION_AI";
  const REMOVE_OPTION_AI = "REMOVE_OPTION_AI";
  const CLEAN_QUESTIONS_AI = "CLEAN_QUESTIONS_AI";
  const ADD_OPTION_AI = "ADD_OPTION_AI";

  return {
    ADD_TOOLS,
    SAVED_QUESTIONS,
    UPDATE_QUESTIONS,
    ADD_SECTION,
    CLEAN_QUESTIONS,
    CONDITIONALS_LIST,
    CLEAN_CONDITIONALS_LIST,
    ORGANIZE_TOOLS_AI,
    UPDATE_TOOLS_AI,
    UPDATE_QUESTION_AI,
    UPDATE_OPTION_AI,
    REMOVE_QUESTION_AI,
    REMOVE_OPTION_AI,
    CLEAN_QUESTIONS_AI,
    ADD_OPTION_AI,
  }
};

export default useToolsTypes;
