// Packages
import { useCallback, useEffect, useState, useMemo } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

// Helpers
import useHelpers from "../../../../helpers";

// Actions
import useActions from "../../../../api/actions";

// Components
import useComponents from "../../../../views/components";

const useToolsCreate = () => {
  const { id_tool } = useParams();

  // State
  const [listLenguajes, setListLenguaje] = useState([]);
  const [nameMission, setNameMission] = useState(null);
  const [toolsList, setToolsList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [typeOfSurvey, setTypeOfSurvey] = useState("");
  const [isEditing, setIsEditing] = useState({});
  const [openWarning, setOpenWarning] = useState(false);
  const [objectiveIndexToDelete, setObjectiveIndexToDelete] = useState(null);
  const [toolStatus, setToolStatus] = useState(null);

  const surveyState = useSelector(state => state.addTools);

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useValidators, useNavigation, useToast } = useQuickFunctions();
  const { toolsValidationSchema } = useValidators();
  const { navigateTo } = useNavigation();
  const { info, error, success } = useToast();

  // Actions
  const { useToolsActions, dispatch, useGeneralActions } = useActions();
  const { actAddTools, actGetTool, actUpdateTool, actListTools, actCleanQuestionsAI } = useToolsActions();
  const { actListLenguajes } = useGeneralActions();

  // Components
  const { Toast } = useComponents();

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(toolsValidationSchema),
    defaultValues: {
      validation: false,
      profiling: false,
    },
  });

  const { fields: objectives, append, remove, update } = useFieldArray({
    control,
    name: "objectives",
  });


  useEffect(() => {
    if (errors.objectives) {
      error(<Toast title={"Aun no puedes continuar"} text={"Tienes objetivos vacios ingresa el objetivo o eliminalo para continuar"} />, {
        toastId: 0,
      });
    }
  }, [errors.objectives])

  useEffect(() => {
    const onSuccess = (data) => {
      // Set default value
      reset({
        typeTool: data?.type_tool,
        name: data?.name,
        validation: data?.validation ? true : false,
        publish: data?.publish ? true : false,
        description: data?.description,
        language: data?.languaje,
        use_template: data?.template_id ? true : false,
        is_template: data?.is_template ? true : false,
        template_id: data?.template_id,
        objectives: data?.objectives.map((objective) => ({
          id: objective?.id || null,
          body: objective?.body || "",
        })),
      });
      setIsEditing(data?.objectives.map(() => true));
      setToolStatus(data?.status);
      setNameMission(data?.mission_name ?? null)
    };
    const onError = (error) => {
      console.log(error);
    };
    if (id_tool) {
      dispatch(actGetTool(id_tool, onSuccess, onError));
    }
  }, [id_tool]);

  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath.includes("editar-herramienta")) setTypeOfSurvey("Manual")
  }, [watch("typeTool")]);

  const watchFieldsDateInit = watch("startDate");
  const watchTypeTool = watch("typeTool");
  const watchUseTemplate = watch("use_template");

  const startDate = useMemo(() => {
    return watchFieldsDateInit;
  }, [watchFieldsDateInit]);

  const onSubmit = useCallback(
    (formData) => {
      if (objectives.length > 0) {
        for (const objective of objectives) {
          console.log(objective)
          if (objective.body === "") {
            error(
              <Toast title={"Aun no puedes continuar"} text={"tienes objetivos sin guardar, guárdalos o elimínalo para poder continuar"} />,
              { toastId: 0 }
            );
            return;
          }
        }
      }
      else if (objectives.length === 0) {
        error(
          <Toast text={"Tienes que agregar al menos un objetivo"} />,
          { toastId: 0 }
        );
        return;
      }

      let objectivesData = objectives.map((objective) => (objective.body));
      const data = {
        name: formData.name,
        description: formData.description,
        type_tool: formData.typeTool,
        released: true,
        languaje: formData.language,
        validation: formData.validation ? true : false,
        valid_media: formData.name ? true : false,
        publish: formData.publish || formData.typeTool !== "Survey" ? true : false,
        ...(formData.typeTool === "Survey" && { is_template: formData.use_template ? false : formData.is_template }),
        ...(formData.typeTool === "Survey" && formData.use_template && { template_id: formData.template_id?.value }),
        ...(formData.typeTool === "VideoCall" || formData.typeTool === "Survey") && {
          objectives: objectivesData,
        },
      };

      if (id_tool) {
        const onSuccess = (data) => {
          const { tools } = data
          if (formData.typeTool !== "VideoCall" && formData.typeTool !== "Referral") {
            success(<Toast text={"Herramienta editada con éxito!"} />, {
              toastId: 0,
            });
          }
          switch (data.type_tool) {
            case "Survey":
              navigateTo(`/herramientas/encuesta/${tools.id}/secciones/listado`);
              break;
            case "PassiveTracking":
              navigateTo(`/herramientas/passive-tracking/${tools.id}/editar`, data);
              break;
            case "VideoCall":
              navigateTo(`/herramientas/videollamadas/editar`, data);
              break
            case "Referral":
              navigateTo(`/herramientas/referidos/editar`, data);
              break
          }
        };

        const onError = (e) => {
          error(<Toast text={e} />, {
          });
        };
        data.objectives = formData.objectives.map((objective) => ({
          id: objective?.id || null,
          body: objective?.body || "",
        }));
        dispatch(actUpdateTool({ data, id: id_tool }, onSuccess, onError));
      } else {
        const onSuccess = ({ tools }) => {
          switch (formData.typeTool) {
            case "Survey":
              setShowModal(true);
              break;
            case "PassiveTracking":
              navigateTo(`/herramientas/passive-tracking/${tools.id}/crear`);
              break;
          }
        };

        const onError = (e) => {
          console.log(e);
        };
        if (formData.typeTool !== "VideoCall" && formData.typeTool !== "Referral") {
          dispatch(actAddTools(data, onSuccess, onError));
        } else {
          switch (formData.typeTool) {
            case "VideoCall":
              navigateTo(`/herramientas/videollamadas/crear`, data);
              break
            case "Referral":
              navigateTo(`/herramientas/referidos/crear`, data);
              break
          }
        }
      }
    },

    [id_tool, objectives]
  );

  useEffect(() => {
    const onSuccess = (data) => {
      setListLenguaje(
        data.map((v) => {
          return { ...v, label: v.name, value: v.iso };
        })
      );
    };
    actListLenguajes(onSuccess);
  }, []);

  useEffect(() => {
    const onSuccess = ({ data }) => {
      setToolsList(data.map((v) => {
        return { ...v, label: v.name, value: v.id };
      }))
    }
    const onError = (error) => {
      console.log(error)
    }
    dispatch(actListTools({ page: 1, template: true }, onSuccess, onError))
  }, []);

  const handleChooseSurveyType = (surveyType) => {
    if (surveyType === "ai") dispatch(actCleanQuestionsAI()), navigateTo(`/herramientas/encuesta/ia`);
    if (surveyType === "manual") setTypeOfSurvey("Manual"), navigateTo(`/herramientas/encuesta/${surveyState.toolsId}/secciones/listado`);
    setShowModal(false);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    reset({ typeTool: "" });
  };

  const handleEditToggle = (e, index) => {
    e.preventDefault();

    if (!isEditing[index]) {
      if (objectives[index].body === "") {
        info(<Toast text={"Objetivo creado con éxito"} />, {
          toastId: 0,
        });
      }
      else {
        info(<Toast text={"Objetivo editado con éxito!"} />, {
          toastId: 0,
        });
      }
      const updatedObjective = watch(`objectives[${index}]`);
      update(index, updatedObjective);
    }

    setIsEditing((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };


  const handleOpenWarning = (index) => {
    setObjectiveIndexToDelete(index);
    setOpenWarning(!openWarning);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };

  const removeObjective = (index) => {
    remove(index);
    setIsEditing((prev) => ({
      ...prev,
      [index]: false,
    }));
    setObjectiveIndexToDelete(null);
    setOpenWarning(false);
  };


  return {
    control,
    handleCloseModal,
    handleChooseSurveyType,
    handleSubmit,
    onSubmit,
    startDate,
    id_tool,
    listLenguajes,
    nameMission,
    showModal,
    toolsList,
    typeOfSurvey,
    watchUseTemplate,
    watchTypeTool,
    objectives,
    append,
    remove,
    handleEditToggle,
    isEditing,
    openWarning,
    handleOpenWarning,
    handleCloseWarning,
    objectiveIndexToDelete,
    removeObjective,
    toolStatus,
  };
};

export default useToolsCreate;
