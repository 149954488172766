// Packages
import { useState, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import debounce from "lodash.debounce";
import useControllers from "../..";

// Actions
import useActions from "../../../api/actions";

// Components
import useComponents from "../../../views/components";


// Helpers
import useHelpers from "../../../helpers";

const useAnalytic = () => {

  const [listSurvey, setListSurvey] = useState([]);
  const [listCountries, setListCountries] = useState([]);
  const [listQuestions, setListQuestions] = useState([]);
  const [listReport, setListReport] = useState([]);
  const [listProfilingQuestion, setListProfilingQuestion] = useState({});
  const [selectTool, setSelectTool] = useState(null);
  const [valuesFilter, setValuesFilter] = useState(null);
  const [warningQuestionRequired, setWarningQuestionRequired] = useState({ demographic: false, attitudinal: false, show: false });
  const [toolTranscription, setToolTranscription] = useState(null);
  const [taskInProgress, setTaskInProgres] = useState(true);
  const [showDocumentTypeModal, setShowDocumentTypeModal] = useState(false);
  const [showCopilotModal, setShowCopilotModal] = useState(false);
  const [requestFinished, setRequestFinished] = useState(true);
  const [toolAnalytics, setToolAnalytics] = useState(null);
  const [showCopilotBox, setShowCopilotBox] = useState(false);
  const [isRefined, setIsRefined] = useState(false);
  const copilotAnalyticsContainer = useRef(null);

  // Components
  const { Toast } = useComponents();

  // Actions
  const { useToolsActions, dispatch, useGeneralActions } = useActions();
  const {
    actListTools,
    actGetAnalyticTools,
    actGetQuestionsTool,
    actGetProfilingQuestion,
    actCopilotAnalyticTool,
    actGetCopilotAnalyticToolStatus,
  } = useToolsActions();

  const { control, setError, handleSubmit, watch, resetField } = useForm({
    mode: "all",
    defaultValues: {
      reportType: "default",
    },
  });

  const { control: modalControl, handleSubmit: handleCopilotFileSubmit, watch: modalWatch } = useForm({
    mode: "all",
    defaultValues: {
      type_document: "pdf",
    },
  });

  const { control: copilotModalControl, handleSubmit: handleCopilotSubmit } = useForm({
    mode: "all",
  });

  const { actExportExel, actListCountries } = useGeneralActions();

  const { useGeneralHooks } = useControllers();
  const { createDownloadExel } = useGeneralHooks();

  // Helpers
  const { useQuickFunctions } = useHelpers();
  const { useGeneral, useToast } = useQuickFunctions();
  const { formatDate, generatePDF, generateWord } = useGeneral();
  const { error } = useToast();

  const reportType = watch("reportType");

  useEffect(() => {
    setShowCopilotBox(false);
    setToolAnalytics(null);
    setToolTranscription(null);
  }, [reportType]);

  const loadData = (data) => {
    setListSurvey(
      data.data.map((v) => {
        return { ...v, label: v.name, value: v.id };
      })
    );
  };

  const onSubmitSearch = (e) => {
    const reportType = watch("reportType");
    let toolType = reportType === "videocallTranscription" ? "VideoCall" : "Survey";
    if (e.target.value.length >= 3) {
      dispatch(
        actListTools(
          {
            page: 1,
            tool_type: toolType,
            search: e.target.value,
            reportType: watch("reportType"),
            ...(watch("reportType") === "maz" && { multi_country: true }),
          },
          loadData
        )
      );
    }
  };

  const onChangeSearchSurvey = useCallback(debounce(onSubmitSearch, 750), []);

  const onSelectSurvey = (value) => {
    const onSuccess = (data) => {
      setListQuestions(
        data.data.map((v) => {
          return { label: v.title, value: v.id };
        })
      );
      setTaskInProgres(value.status === "Finalizado" ? false : true);
    };

    setSelectTool(value);
    setListCountries(
      value?.countries?.length > 0
        ? value.countries.map((v) => {
          return { label: v.name, value: v.id };
        })
        : []
    );
    if (value && value?.id) {
      dispatch(actGetQuestionsTool(value?.id, onSuccess));
    }
  };

  const createExcel = (string, url) => {
    // Grupos para preguntas attitudinales y demográficas
    const attitudinalQuestions = [],
      demographicQuestions = [];

    if (watch("reportType") === "reportProfile") {
      const questions = watch();
      // Recorremos el objeto para separar las preguntas
      for (const key in questions) {
        const value = questions[key];
        if (value === true) {
          const questionNumber = key.split("_")[1];
          const questionType = key.split("_")[0];
          if (questionType === "question-attitudinal") {
            attitudinalQuestions.push(questionNumber);
          } else if (questionType === "question-demographic") {
            demographicQuestions.push(questionNumber);
          }
        }
      }
    }

    const data = {
      ...(watch("search") && { tool_id: watch("search").id }),
      reportType: watch("reportType"),
      ...(watch("country_id") !== "" && { country_id: watch("country_id") }),
      ...(watch("question_id") !== "" &&
        watch("question_id") &&
        watch("reportType") === `${string}` && {
        question_id: watch("question_id"),
      }),
      ...(demographicQuestions.length > 0 && {
        questions_demographic: demographicQuestions.join(","),
      }),
      ...(attitudinalQuestions.length > 0 && {
        questions_attitudinal: attitudinalQuestions.join(","),
      }),
      ...(watch("range_date") &&
        watch("range_date")[0] && {
        start_date: formatDate(watch("range_date")[0], "Y-mm-dd", false),
      }),
      ...(watch("range_date") &&
        watch("range_date")[0] &&
        !!watch("range_date")[1] && {
        end_date: formatDate(watch("range_date")[1], "Y-mm-dd", false),
      }),
    };
    actExportExel(
      `${url}`,
      null,
      (result) => {
        createDownloadExel(result);
      },
      () => { },
      data
    );
  };

  const onClickClear = () => resetField("range_date");

  const handleDownloadExcel = () => {
    if (!watch("search") && watch("reportType") !== "reportProfile") {
      setError("search", { type: "required", message: "Campo requerido" });
      return;
    }
    if (!watch("country_id") && watch("reportType") !== "maz") {
      setError("country_id", { type: "required", message: "Campo requerido" });
      return;
    }
    if (watch("reportType") === "reportProfile") {
      if (!watch("range_date")[0] || !watch("range_date")[1]) {
        setError("range_date", { type: "required", message: "Selecciona un rango de fechas valido" });
        return;
      }
      const demographicSelected = listProfilingQuestion.demographic.some(q => watch(`question-demographic_${q.id}`));
      const attitudinalSelected = listProfilingQuestion.attitudinal.some(q => watch(`question-attitudinal_${q.id}`));

      setWarningQuestionRequired({ demographic: false, attitudinal: false, show: false });

      if (!demographicSelected && !attitudinalSelected) {
        setWarningQuestionRequired({ demographic: true, attitudinal: true, show: true });
        return;
      }

      if (!demographicSelected) {
        setWarningQuestionRequired(prevState => ({ ...prevState, demographic: true, show: true }));
        return;
      }

      if (!attitudinalSelected) {
        setWarningQuestionRequired(prevState => ({ ...prevState, attitudinal: true, show: true }));
        return;
      }
    }
    if (watch("reportType") === "detailedSampling" || watch("reportType") === "detailedSamplingProfile") {
      if (!watch("question_id")) {
        setError("question_id", { type: "required", message: "Campo requerido" });
        return;
      }
    }
    if (
      valuesFilter !== null ||
      watch("reportType") !== "default" ||
      watch("reportType") !== "defaultProfile"
    ) {
      watch("reportType").includes("Profile")
        ? createExcel("detailedSamplingProfile", "AnalyticInformationMemberProfile")
        : createExcel("detailedSampling", "AnalyticInformationMember");
    }
  };

  const onSubmit = (data) => {
    setShowCopilotBox(false);
    const { search, country_id } = data;
    const reportType = watch("reportType");

    if (search && country_id) {
      const onSuccess = (resp) => {
        if (reportType === "videocallTranscription") {
          setToolTranscription(resp.data.transcription);
        }
        else {
          setListReport(resp);
        }
      };

      // Crear formData basado en el tipo de reporte
      const formData = {
        tool_id: search.id,
        country_id: country_id,
        ...(reportType === "videocallTranscription" && { reportType }),
      };

      // Actualizar filtros y despachar acción
      setValuesFilter(formData);
      dispatch(actGetAnalyticTools(formData, onSuccess));
    } else {
      // Validación de campos requeridos
      if (!search) setError("search", { type: "required", message: "Campo requerido" });
      if (!country_id) setError("country_id", { type: "required", message: "Campo requerido" });
    }
  };


  const dataListCountries = (data) => {
    const countries = _.map(data, (v) => {
      return { label: v.name, value: v.id };
    });
    setListCountries(countries);
  };

  const dataListProfilingQuestion = (data) => {
    setListProfilingQuestion(data);
  };

  useEffect(() => {
    if (watch("reportType") === "reportProfile") {
      actListCountries(dataListCountries);
    } else {
      setListCountries([]);
    }
  }, [watch("reportType")]);

  useEffect(() => {
    if (watch("reportType") === "reportProfile" && watch("country_id") !== "") {
      dispatch(
        actGetProfilingQuestion(
          {
            countryId: watch("country_id"),
          },
          dataListProfilingQuestion
        )
      );
    } else {
      setListProfilingQuestion([]);
    }
  }, [watch("reportType"), watch("country_id")]);

  useEffect(() => {
    resetField("search");
  }, [reportType]);

  const executeCopilotAnalytics = (formData) => {
    const maxAttempts = 20;
    let attempts = 0;

    const startAnalytics = () => {
      dispatch(
        actCopilotAnalyticTool(
          formData,
          onSuccessStart,
          onErrorStart
        )
      );
    };

    const onSuccessStart = (data) => {
      if (!data.success) {
        error(
          <Toast text={data.message} state="error" />,
          { toastId: "analytic-tool-error" }
        );
        setRequestFinished(true);
        setShowCopilotBox(false);
        return;
      }

      const checkStatus = () => {
        if (requestFinished) {
          setRequestFinished(false);
        }
        attempts++;
        if (attempts > maxAttempts) {
          setRequestFinished(true);
          setShowCopilotBox(false);
          error(
            <Toast text="Maximo de intentos alcanzado" state="warning" />,
            { toastId: "max-attempts" }
          );
          return;
        }

        dispatch(
          actGetCopilotAnalyticToolStatus(
            formData,
            (statusData) => {
              if (statusData.success) {
                setToolAnalytics(statusData.data.trim());
                setIsRefined(statusData.is_refined);
                setRequestFinished(true);
                clearInterval(intervalId);
              }
            },
            () => {
              setRequestFinished(true);
              setShowCopilotBox(false);
              error(
                <Toast text={"Algo salio mal, intenta de nuevo"} state={"error"} />,
                { toastId: "analytic-tool-error" }
              );
              clearInterval(intervalId);
            }
          )
        );
      };

      // Ejecutar inmediatamente
      checkStatus();

      // Configurar el intervalo para 30 segundos
      const intervalId = setInterval(() => {
        checkStatus();
      }, 30000);
    };

    const onErrorStart = (e) => {
      error(
        <Toast text={e.message} state={"error"} />,
        { toastId: "analytic-tool-error" }
      );
    };

    startAnalytics();
  };

  const handleGetAnalyticTools = () => {
    if (!watch("country_id")) {
      setError("country_id", { type: "required", message: "Campo requerido" });
      return;
    }
    setRequestFinished(false);
    const formData = {
      tool_id: selectTool.id,
      country_id: watch("country_id"),
      reportType: watch("reportType") === "videocallTranscription" ? "videoCall" : watch("reportType"),
    };

    setShowCopilotBox(true);
    executeCopilotAnalytics(formData);
  };


  const handleOpenDocumentTypeModal = (event) => {
    event.preventDefault();
    setShowDocumentTypeModal(!showDocumentTypeModal);
  };

  const handleDownloadAnalytics = async () => {
    if (copilotAnalyticsContainer.current) {
      if (modalWatch("type_document") === "pdf") {
        try {
          await generatePDF(copilotAnalyticsContainer.current, selectTool);
        } catch (e) {
          error(<Toast text={"Error al generar el PDF. Por favor, inténtelo de nuevo más tarde."} state="error" />, { toastId: "pdf-error" });
        }
      } else {
        try {
          await generateWord(copilotAnalyticsContainer.current, selectTool);
        }
        catch (e) {
          error(<Toast text={"Error al generar el Word. Por favor, inténtelo de nuevo más tarde."} state="error" />, { toastId: "word-error" });
        }
      }
    }
  };

  const handleShowCopilotModal = () => {
    setShowCopilotModal(!showCopilotModal);
  };

  const onSubmitCopilot = (data) => {
    const { copilot_prompt } = data;
    const formData = {
      tool_id: selectTool.id,
      country_id: watch("country_id"),
      reportType: watch("reportType") === "videocallTranscription" ? "videoCall" : watch("reportType"),
      ...copilot_prompt && { prompt: copilot_prompt },

    };

    setRequestFinished(false);
    handleShowCopilotModal();
    executeCopilotAnalytics(formData);
  };


  return {
    control,
    listReport,
    onChangeSearchSurvey,
    listSurvey,
    onSelectSurvey,
    handleSubmit,
    onSubmit,
    listCountries,
    handleDownloadExcel,
    watch,
    listQuestions,
    onClickClear,
    listProfilingQuestion,
    warningQuestionRequired,
    toolTranscription,
    taskInProgress,
    handleGetAnalyticTools,
    toolAnalytics,
    handleOpenDocumentTypeModal,
    showDocumentTypeModal,
    modalControl,
    handleDownloadAnalytics,
    handleCopilotSubmit,
    onSubmitCopilot,
    showCopilotModal,
    handleShowCopilotModal,
    requestFinished,
    copilotModalControl,
    modalControl,
    showCopilotBox,
    copilotAnalyticsContainer,
    handleCopilotFileSubmit,
    toolAnalytics,
    isRefined,
  };
};

export default useAnalytic;
