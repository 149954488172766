import React, { useEffect, useRef } from 'react';

const CopilotInform = ({ htmlContent }) => {
  const iframeRef = useRef(null);

  useEffect(() => {
    const adjustIframeHeight = () => {
      if (iframeRef.current) {
        const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;
        if (iframeDoc && iframeDoc.body) {
          iframeRef.current.style.height = `${iframeDoc.body.scrollHeight}px`;
        }
      }
    };

    if (iframeRef.current) {
      const iframe = iframeRef.current;
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;

      if (iframeDoc) {
        // Escribir el contenido dentro del iframe
        iframeDoc.open();
        iframeDoc.write(htmlContent);
        iframeDoc.close();

        // Esperar a que el contenido del iframe esté completamente cargado
        iframe.onload = adjustIframeHeight;
      }
    }

    return () => {
      if (iframeRef.current) {
        iframeRef.current.onload = null; // Limpiar el evento al desmontar
      }
    };
  }, [htmlContent]);

  return (
    <iframe
    ref={iframeRef}
    className="w-full h-full border-none"
  />
  );
};

export default CopilotInform;
