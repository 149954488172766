// Packages
import styled from "styled-components";
import tw from "twin.macro";
import TableCell from "@mui/material/TableCell";

export const StyledTableCell = styled(TableCell)`
  ${props => props.font ? `font-weight: ${props.font} !important;` : 'font-weight: 300 !important;'}
`;

export const StyledContainerPaper = styled.div`
  .MuiPaper-root {
    background: white !important;
    padding: 2rem 1rem;
    margin: 0 1.5rem 2rem;
    border-radius: 10px;
    max-width: 96%;
  }
  thead {
    tr {
        ${tw`uppercase`}
        th {
            color: #B6BBC1 !important;
            font-weight: 600 !important;
            font-size: 0.75rem !important;
            ${tw`p-1 truncate`}
        }
    }
  }
  tbody {
    tr {
        ${tw`truncate`}
        letter-spacing: -0.006em;
        td {
            color: #363635 !important;
            font-size: 0.75rem !important;
            ${tw`border-dashed border-b border-neutral-70`}
        }
    }
    tr:nth-of-type(even) {
        td {
            ${tw`bg-neutral-20`}
        }
        
    }
  }
`;

export const ConversationContentContainer = styled.div`
  ${tw`mb-8`};
  strong {
    ${tw`text-neutral-70`}
  }
`;

export const ScrollableDiv = styled.div`
  ${tw`overflow-y-auto h-full mt-8`};

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: #000000;
    border-radius: 180px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #FFB62A;
    border-radius: 180px;
  }
  
  &:hover {
    pointer-events: auto; /* Permite interacción */
    scroll-behavior: smooth; /* Scroll fluido */
  }
`;

export const RadioButtonContainer = styled.div`
  ${tw`w-full h-[50px] flex flex-row mb-8 rounded-lg border px-2 items-center`};
  input[type="radio"] {
    transform: scale(1.5);
    border-radius: 50%;
    accent-color: #FFB62A;
    border-color: #FFFFFF;
  }
`;

export const StyledSurveyModalClose = styled.img`
  cursor: pointer;
  height: 1.125rem
  width: 1.125rem
`;
