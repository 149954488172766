// Packages
import React from "react";

// Components
import useComponents from "../../../components";

// Controllers
import useControllers from "../../../../controllers";

// Assets
import toolCreateAI from "../../../../assets/images/survey-ai.jpg";
import toolCreateManual from "../../../../assets/images/survey-manual.jpg";
import iconCloseCircle from "../../../../assets/images/icon-close-circle.svg";
import useIcons from "../../../../assets/icons";

// Styles
import {
  StyledSurveyCreationImg,
  StyledSurveyCreationContainer,
  StyledSurveyCreationTitle,
  StyledSurveyCreationDescription,
  StyledSurveyModalContainer,
  StyledSurveyModalClose,
  StyledSurveyModalContent,
  StyledModalTitle,
  StyledModalDescription,
  StyledAddButton,
} from "./CreateTools.styles";

const ToolsCreate = ({ }) => {
  // Components
  const {
    CardWrapper,
    ContentWrappers,
    Typography,
    TextFieldControlled,
    SelectFieldControlled,
    CheckboxControlled,
    useButtons,
    useModals,
    InputSwitchControlled,
    TextAreaFieldControlled,
    AutocompleteFieldControlled,
  } = useComponents();

  const { DefaultButton } = useButtons();
  const { ModalCustom, DefaultModal } = useModals();
  const { IconPlus, IconDelete, IconEdit, IconCheckNormal } = useIcons();

  // Controllers
  const { useScreenHooks } = useControllers();
  const { useTools } = useScreenHooks();
  const { useToolsCreate } = useTools();

  const {
    control,
    handleCloseModal,
    handleChooseSurveyType,
    handleSubmit,
    onSubmit,
    id_tool,
    listLenguajes,
    nameMission,
    showModal,
    toolsList,
    typeOfSurvey,
    watchTypeTool,
    watchUseTemplate,
    objectives,
    append,
    remove,
    handleEditToggle,
    isEditing,
    openWarning,
    handleOpenWarning,
    handleCloseWarning,
    objectiveIndexToDelete,
    removeObjective,
    toolStatus,
  } = useToolsCreate();

  return (
    <>
      <ContentWrappers
        gridColums={["1fr"]}
        gap={{
          row: "0px",
          column: "20px",
        }}
        contentWidth="1200px"
        as="section"
      >
        <CardWrapper>
          <Typography variant="h3">1. CONFIGURACIÓN GENERAL DE HERRAMIENTA</Typography>
          <form
            className="mt-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex row">
              <div className="flex-1 m-2">
                <SelectFieldControlled
                  label="Tipo de herramienta"
                  helperText="Debes seleccionar un tipo de herramienta"
                  name="typeTool"
                  id="typeTool"
                  disabled={id_tool ? true : false}
                  control={control}
                  options={[
                    {
                      value: "Survey",
                      label: "Encuesta",
                    },
                    {
                      value: "PassiveTracking",
                      label: "Passive Tracking",
                    },
                    {
                      value: "VideoCall",
                      label: "Video llamada",
                    },
                    {
                      value: "Referral",
                      label: "Referidos",
                    },
                  ]}
                />
              </div>
              <div className="flex-1">
                <TextFieldControlled
                  label="Nombre de la herramienta"
                  tooltipTitle="Nombre de la herramienta"
                  placeholder="Nombre"
                  helperText="Debes especificar un nombre"
                  name="name"
                  id="name"
                  control={control}
                />
              </div>
              <div className="flex-1 m-2">
                <SelectFieldControlled
                  label="Idioma"
                  helperText="Debes seleccionar un idioma"
                  name="language"
                  id="language"
                  options={listLenguajes}
                  control={control}
                />
              </div>
            </div>
            <div className="flex row justify-between">
              {watchTypeTool === "Survey" && (
                <div className="mb-4">
                  <InputSwitchControlled
                    label="Deseas usar una plantilla?"
                    name="use_template"
                    id="use_template"
                    control={control}
                  />
                </div>
              )}
              {watchTypeTool === "Survey" && (
                <div className="mt-2">
                  <CheckboxControlled
                    label="Publicar encuesta"
                    name="publish"
                    id="publish"
                    control={control}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center flex-1">
              {watchTypeTool === "PassiveTracking" && (
                <CheckboxControlled
                  label="Solicitar foto y video por paso"
                  name="media"
                  id="media"
                  control={control}
                />
              )}
            </div>
            {watchUseTemplate && (
              <div className="flex-1">
                <AutocompleteFieldControlled
                  label="Plantilla a usar"
                  placeholder="Busca por el nombre de la plantilla que quieres usar"
                  name="template_id"
                  id="template_id"
                  control={control}
                  options={toolsList}
                />
              </div>
            )}
            <TextAreaFieldControlled
              label="Descripción"
              placeholder="Describe brevemente la herramienta"
              name="description"
              id="description"
              control={control}
              maxLength={255}
            />
            {
              (
                <>

                  {watchTypeTool === "Survey" && !watchUseTemplate && (
                    <div className="mb-4 space-y-2">
                      <Typography variant="h3" className={"uppercase"}>
                        2. Plantilla
                      </Typography>
                      <InputSwitchControlled
                        label="Marcar herramienta como plantilla"
                        name="is_template"
                        id="is_template"
                        control={control}
                      />
                    </div>
                  )}
                  {(watchTypeTool === "VideoCall" || watchTypeTool === "Survey") && (
                    <div className="mt-4 mb-4 space-y-2">
                      <Typography className="mt-2 mb-4 uppercase" variant="h3">
                        3. Objetivos
                      </Typography>
                      {objectives.map((objective, index) => (
                        <div
                          key={objective.id || index}
                          style={{ display: "flex", alignItems: "center", marginBottom: "8px" }}
                        >
                          <TextFieldControlled
                            label={`Objetivo ${index + 1}`}
                            name={`objectives[${index}].body`}
                            id={`objectives[${index}].body`}
                            control={control}
                            disabled={isEditing[index]}
                          />
                          <button
                            type="button"
                            onClick={(e) => handleEditToggle(e, index)}
                            style={{ marginLeft: "8px" }}
                            title={isEditing[index] ? "Editar objetivo" : "Guardar objetivo"}
                            disabled={toolStatus && toolStatus !== "Pendiente"}
                          >
                            {isEditing[index] ? <IconEdit /> : <IconCheckNormal color="gray" />}
                          </button>
                          <button
                            type="button"
                            onClick={() => handleOpenWarning(index)}
                            style={{ marginLeft: "8px" }}
                            title="Eliminar objetivo"
                            disabled={toolStatus && toolStatus !== "Pendiente"}
                          >
                            <IconDelete />
                          </button>
                        </div>
                      ))}
                      {objectives.length < 5 ? (
                        <StyledAddButton>
                          <button
                            className="w-10 h-10 flex items-center justify-center rounded-sm"
                            type="button"
                            onClick={() => append({ body: "" })}
                            disabled={toolStatus && toolStatus !== "Pendiente"}
                          >
                            <IconPlus color="#000000" />
                            <p className="ml-2">Agregar objetivo</p>
                          </button>
                        </StyledAddButton>
                      ) : (
                        <Typography className="text-gray-500 text-sm">
                          Has alcanzado el límite de objetivos.
                        </Typography>
                      )}
                    </div>
                  )}
                  {nameMission && (
                    <div>
                      <Typography variant="h3">
                        {watchTypeTool === "Survey" ? 3 : 2}. MISIÓN VINCULADA
                      </Typography>
                      <ContentWrappers gridColums={["48%"]} className="mt-6">
                        <Typography variant="caption">{nameMission}</Typography>
                      </ContentWrappers>
                    </div>
                  )}
                  <div className="justify-center flex">
                    <DefaultButton rounded={true} color="primary" type="submit">
                      Siguiente
                    </DefaultButton>
                  </div>
                </>
              )
            }
          </form>
        </CardWrapper>
      </ContentWrappers>

      <DefaultModal borderRadius={"16px"} isOpen={openWarning} showIcon={false}>
        <div className="flex items-start flex-col space-y-5 w-[588px] py-10 px-14 rounded-[32px]">
          <Typography variant="h3" className="text-center">
            {"Eliminar"}
          </Typography>
          <Typography variant="body1" className="text-justify">
            {
              "Está a punto de eliminar un objetivo. ¿Quiere continuar con esta acción? Esta acción no se puede deshacer."}
          </Typography>
          <div className="w-full flex justify-center gap-x-4">
            <DefaultButton color="secondary" rounded onClick={() => handleCloseWarning()}>
              Cancelar
            </DefaultButton>
            <DefaultButton color="error" rounded onClick={() => {removeObjective(objectiveIndexToDelete)}}>
              Eliminar
            </DefaultButton>
          </div>
        </div>
      </DefaultModal>

      <ModalCustom isOpen={showModal} padding="1.5rem">
        <StyledSurveyModalContainer>
          <div className="justify-end flex">
            <StyledSurveyModalClose
              alt="cerrar"
              onClick={handleCloseModal}
              src={iconCloseCircle}
            />
          </div>

          <StyledSurveyModalContent>
            <StyledModalTitle>
              ¿Cómo quieres generar la encuesta?
            </StyledModalTitle>
            <StyledModalDescription>
              Selecciona uno de los métodos que tenemos para generar la encuesta que necesitas
            </StyledModalDescription>
            <ContentWrappers
              gridColums={["46%", "46%"]}
              gap={{
                row: "0px",
                column: "8%",
              }}
            >
              <CardWrapper padding="0">
                <StyledSurveyCreationImg src={toolCreateManual} alt="create manual" />
                <StyledSurveyCreationContainer>
                  <StyledSurveyCreationTitle>
                    Hazlo tú mismo
                  </StyledSurveyCreationTitle>
                  <StyledSurveyCreationDescription>
                    Crea manualmente tu encuesta.
                  </StyledSurveyCreationDescription>
                  <div className="my-1 flex justify-center">
                    <DefaultButton
                      color="primary"
                      onClick={() => handleChooseSurveyType("manual")}
                      rounded
                    >
                      Crear encuesta
                    </DefaultButton>
                  </div>
                </StyledSurveyCreationContainer>
              </CardWrapper>

              <CardWrapper padding="0">
                <StyledSurveyCreationImg src={toolCreateAI} alt="create AI" />
                <StyledSurveyCreationContainer>
                  <StyledSurveyCreationTitle>
                    Inteligencia Artificial
                  </StyledSurveyCreationTitle>
                  <StyledSurveyCreationDescription>
                    Crea tu encuesta con IA.
                  </StyledSurveyCreationDescription>
                  <div className="my-1 flex justify-center">
                    <DefaultButton
                      color="primary"
                      onClick={() => handleChooseSurveyType("ai")}
                      rounded
                    >
                      Crear encuesta
                    </DefaultButton>
                  </div>
                </StyledSurveyCreationContainer>
              </CardWrapper>
            </ContentWrappers>
          </StyledSurveyModalContent>
        </StyledSurveyModalContainer>
      </ModalCustom>
    </>
  );
};

export default ToolsCreate;
